<template>
  <el-main>
    <page-title show-back-btn />
    <div v-loading="loading.detail" class="partition-area">
      <el-form ref="appForm" :model="appForm" :rules="rules" label-width="180px" :label-suffix="constants.labelSuffix">
        <ics-page-inner title="授信进度">
          <el-col :span="24">
            <el-steps :active="stepActiveIndex" finish-status="success">
              <el-step title="发起申请" />
              <el-step title="补件" />
              <el-step title="审批" />
              <el-step title="完成" />
            </el-steps>
          </el-col>
        </ics-page-inner>
        <div>
          <ics-page-inner title="授信信息">
            <el-col :span="12">
              <el-form-item label="授信编号">
                <p>{{ utils.isEffectiveCommon(creditDetail.creditCode) }}</p>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="融资企业">
                <p>{{ utils.isEffectiveCommon(creditDetail.customerName) }}</p>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="核心企业">
                <p>{{ utils.isEffectiveCommon(creditDetail.coreName) }}</p>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="资方">
                <p>{{ utils.isEffectiveCommon(creditDetail.capName) }}</p>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="申请额度(元)">
                <p>{{ utils.moneyFormat(creditDetail.applyAmount, 2) }}</p>
              </el-form-item>
            </el-col>
          </ics-page-inner>
          <ics-product-info-inner ref="product" :product-info="productInfo" :stage="productKey" />
          <ics-page-inner title="资产信息-基础合同">
            <el-col :span="24">
              <div class="partition-area">
                <el-table :data="contractBaseInfo" class="table-input" max-height="250">
                  <el-table-column prop="contracNo" label="基础合同编号" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="100" />
                  <el-table-column prop="contracName" label="基础合同名称" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
                  <el-table-column prop="contracAmount" label="基础合同金额" align="center" :formatter="utils.tableMoneyFormat" show-overflow-tooltip min-width="150" />
                  <el-table-column prop="signTime" label="签订时间" align="center" :formatter="(row, col, cell) => utils.dateFormat(cell)" show-overflow-tooltip min-width="150" />
                  <el-table-column prop="dueTime" label="合同到期时间" align="center" :formatter="(row, col, cell) => utils.dateFormat(cell)" show-overflow-tooltip min-width="150" />
                  <el-table-column label="操作" align="center" fixed="right" show-overflow-tooltip min-width="150">
                    <template v-slot="scope">
                      <el-link type="danger" class="text-btn" @click="clickDialog(scope.row)">
                        查看发票
                      </el-link>
                      <el-link type="danger" class="text-btn" @click="utils.downloadP('other', scope.row.filesList[0].url)">
                        下载
                      </el-link>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-col>
          </ics-page-inner>
          <ics-bank-files-inner :loading-submit="loading" :bank-files-info="bankFilesInfo" :bank-key="bankKey" :method="method" :flow-state-code="creditDetail.flowStateCode" @submit="submitForms" />
          <ics-page-inner v-if="productKey === 2" title="" :show-header="true">
            <ics-button-inner :loading="loading.submit" submit-title="提交" cancel-title="返回" @submit="commit" />
          </ics-page-inner>
        </div>
      </el-form>
    </div>
    <ics-dialog-inner width="60%" :visible.sync="dialog.invoice.visible" :title="dialog.invoice.title" cancel-title="返回" class="form-dialog" :show-submit="false">
      <div class="partition-table">
        <el-table :data="invoicesList" border style="width: 100%" class="table-input" max-height="250">
          <el-table-column prop="invoiceCode" label="发票编号" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="100" />
          <el-table-column prop="invoiceKind" label="发票类型" align="center" :formatter="(row, col, cell) => utils.statusFormat(cell, 'invoiceKindType')" show-overflow-tooltip min-width="100" /><el-table-column prop="buyerTaxName" label="采购方" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
          <el-table-column prop="sellerTaxName" label="销售方" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
          <el-table-column prop="billingDate" label="开票时间" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
          <el-table-column prop="totalAmount" label="金额(元)" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
          <el-table-column prop="totalTax" label="税额(元)" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
          <el-table-column prop="invoiceStatus" label="状态" align="center" :formatter="(row, col, cell) => utils.statusFormat(cell, 'invoiceStatus')" show-overflow-tooltip min-width="150" />
          <el-table-column label="操作" fixed="right" align="center" show-overflow-tooltip min-width="150">
            <template slot-scope="scope">
              <a href="javascript:" class="text-btn" @click="utils.downloadP('invoice', scope.row.invoiceUrl)">下载</a>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </ics-dialog-inner>
  </el-main>
</template>

<script>
import { routeEnterMixin } from '@/assets/js/mixins'
import IcsProductInfoInner from './components/product-info-inner'
import IcsBankFilesInner from './components/bank-files-inner'
import IcsDialogInner from '@/components/dialog-inner'
export default {
  components: { IcsBankFilesInner, IcsProductInfoInner, IcsDialogInner },
  mixins: [routeEnterMixin],
  data() {
    return {
      fileUploadUrl: process.env.VUE_APP_REQUEST_BASE_URL + '/tenant/b/v1/file/upload?token=' + this.$store.state.token,
      creditCode: this.$route.query.creditCode,
      taskId: this.$route.query.taskId,
      stepActiveIndex: 0,
      appForm: {},
      bankKey: 0,
      productKey: 0,
      method: '',
      creditDetail: {},
      contractBaseInfo: [],
      productInfo: {},
      bankFilesInfo: {},
      invoicesList: [],
      loading: {
        detail: false,
        submit: false
      },
      dialog: {
        invoice: {
          visible: false,
          title: '查看发票信息'
        }
      },
      rules: {}
    }
  },
  created() {
    if (this.creditCode) {
      this.getDetail()
    }
  },
  methods: {
    getDetail() {
      this.loading.detail = false
      this.api.credit.creditDetails({creditCode: this.creditCode}).then(result => {
        const data = result.data.data
        this.creditDetail = data.credit
        this.contractBaseInfo = data.contractBaseInfo
        this.bankKey = data.snapshot.bankKey
        if (data.credit.flowStateCode === 'Supplement_cap' && data.snapshot.productKey === 0) {
          this.productKey = 2
        }
        if (data.snapshot.productJson) {
          const productJson = JSON.parse(data.snapshot.productJson) || {}
          this.productInfo = productJson
          if (Number(productJson.repaymentMethod) === 0) {
            this.method = 0
          } else if (Number(productJson.repaymentMethod) === 1) {
            this.method = 1
          } else {
            this.method = ''
          }
        }
        if (data.snapshot.bankJson) {
          this.bankFilesInfo = JSON.parse(data.snapshot.bankJson)
        }
        this.bankFilesInfo.files = data.files
        switch (data.credit.flowStateCode) {
          case 'Wait':
            this.stepActiveIndex = 0
            break
          case 'Supplement_tenant':
            this.stepActiveIndex = 1
            break
          case 'Supplement_cap':
            this.stepActiveIndex = 1
            break
          case 'Cap_approve':
            this.stepActiveIndex = 2
            break
          case 'Fail':
            this.stepActiveIndex = 4
            break
          case 'Completed':
            this.stepActiveIndex = 4
            break
          default:
            this.stepActiveIndex = 0
        }
      }).finally(() => {
        this.loading.detail = false
      })
    },
    clickDialog (row) {
      this.invoicesList = row.invoicesList || []
      this.dialog.invoice.visible = true
    },
    submitForms (formData, type) {
      if (this.method === 0) {
        this.api.eAccount.getAccountInfoByNumber(formData.bankAccount).then(result => {
          const accountInfo = result.data.data || {}
          if (accountInfo.signStauts !== '20') {
            this.$message.error('该账户还未开户成功')
            return false
          }
          return new Promise((resolve, reject) => {
            this.loading.submit = true
            let data = formData || {}
            const task = {
              btnCode: 'pass',
              btnName: '通过',
              opinion: '补件通过',
              taskData: {
                bank: JSON.stringify({
                  firmName: data.firmName,
                  bankName: data.bankName,
                  bankAccount: data.bankAccount
                })
              },
              files: data.files
            }
            this.api.contract.isContractSign({creditCode: this.creditCode}).then(result => {
              if (result.data.success === true) {
                this.api.credit.complete(this.taskId, task).then(result => {
                  if (result.data.success === true) {
                    this.loading.submit = false
                    this.$message.success('操作成功')
                    this.$router.back()
                  } else {
                    this.$message.error(result.data.message)
                  }
                  resolve(result.data.data)
                }).catch(e => {
                  reject(e)
                }).finally(() => {
                  this.loading.submit = false
                })
              } else {
                this.$message.error(result.data.message)
                this.loading.submit = false
              }
            }).catch(e => {
              this.loading.submit = false
            })
          })
        }).finally(() => {
          return false
        })
      } else {
        return new Promise((resolve, reject) => {
          this.loading.submit = true
          let data = formData || {}
          const task = {
            btnCode: 'pass',
            btnName: '通过',
            opinion: '补件通过',
            taskData: {
              bank: JSON.stringify({
                firmName: data.firmName,
                bankName: data.bankName,
                bankAccount: data.bankAccount
              })
            },
            files: data.files
          }
          this.api.contract.isContractSign({creditCode: this.creditCode}).then(result => {
            if (result.data.success === true) {
              this.api.credit.complete(this.taskId, task).then(result => {
                if (result.data.success === true) {
                  this.loading.submit = false
                  this.$message.success('操作成功')
                  this.$router.back()
                } else {
                  this.$message.error(result.data.message)
                }
                resolve(result.data.data)
              }).catch(e => {
                reject(e)
              }).finally(() => {
                this.loading.submit = false
              })
            } else {
              this.$message.error(result.data.message)
              this.loading.submit = false
            }
          }).catch(e => {
            this.loading.submit = false
          })
        })
      }
    },
    commit () {
      this.$refs.product.$refs.appForm.validate().then(() => {
        this.loading.submit = true
        const appForm = this.$refs.product.appForm
        const productDetail = this.$refs.product.productDetail
        const productSnapshot = {
          capId: productDetail.capId,
          capName: productDetail.capName,
          capCode: productDetail.capCode,
          id: productDetail.id,
          productCode: productDetail.productCode,
          productName: productDetail.productName,
          repayment: productDetail.repayment,
          productTypeName: productDetail.productTypeName,
          productFinancing: productDetail.productFinancing,
          state: productDetail.state,
          isSupplement: 'yes',
          repaymentMethod: productDetail.repaymentMethod,
          serviceChargeMethod: productDetail.serviceChargeMethod,
          maxPeriod: appForm.maxPeriod,
          maxAmount: productDetail.maxAmount,
          roll: productDetail.roll,
          minCycle: appForm.minCycle,
          penaltytRate: appForm.penalTYtRate,
          interestRate: appForm.interestRate,
          overdue: appForm.overdue,
          maxExtension: appForm.maxExtension,
          extensionRate: appForm.extensionRate,
          financing: appForm.financing,
          serviceCharge: appForm.serviceCharge,
          periods: productDetail.periods,
          repaymentDay: productDetail.repaymentDay
        }
        const data = {
          btnCode: 'pass',
          btnName: '通过',
          opinion: '补件通过',
          taskData: {
            productJson: JSON.stringify(productSnapshot)
          }
        }
        this.api.contract.isContractSign({creditCode: this.creditCode}).then(result => {
          if (result.data.success === true) {
            this.api.credit.complete(this.taskId, data).then(result => {
              if (result.data.success === true) {
                this.loading.submit = false
                this.$message.success('操作成功')
                this.$router.back()
              } else {
                this.$message.error(result.data.message)
                this.loading.submit = false
              }
            }).catch(e => {
              this.loading.submit = false
            })
          } else {
            this.$message.error(result.data.message)
            this.loading.submit = false
          }
        }).catch(e => {
          this.loading.submit = false
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
